<script>
import Layout from "../../_layouts/main"
import PageHeader from "@/components/page-header"
import Document from '../../../components/api/document-callback'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Document
  },
  data() {
    return {
      title: this.$t('배팅 취소'),
      apiUrl: this.$store.getters['auth/user'].seamlessCallbackUrl.cancel,
      response: '',
      // username, vendorKey, gameKey, ipAddress, lobbyKey, platform, requestKey
      body: {
        apiKey: {
          type: 'text',
          value: this.$store.getters['auth/user'].apiKey,
          desc: this.$t('text.api.apkKey_desc'),
          required: true,
          readonly: true
        },
        username: {type: 'text', value: 'user1', desc: this.$t('text.api.username_desc'), required: true},
        params: {
          type: 'json', value: JSON.stringify({
            transactionKey: '629b24bb93f3ea9900f0e61e873952a8',
            username: 'top1_user1',
            siteUsername: 'user1',
            vendorKey: 'pragmatic_slot',
            vendorName: '프라그마틱 슬롯',
            gameCategoryKey: 'slot',
            gameKey: 'vs20doghouse',
            gameName: 'The Dog House',
            gameTypeKey: 'slot',
            type: 'cancel',
            key: '631fd2c6f35383513fab5654c',
            parentTransactionKey: null,
            isBonus: false,
            isJackpot: false,
            isPromo: false,
            isFreeGame: false,
            groupKey: '15',
            amount: 300,
            createdAt: '2022-09-13T09:45:58.496Z',
            requestedAt: '2022-09-13T09:45:58.696Z'
          }, null, ' '), desc: '사이트로 전송될 데이터(사이트에 맞게 수정하세요.)', required: true
        },
        requestAt: {
          type: 'text',
          value: new Date().toISOString(),
          desc: this.$t('text.api.requestAt_desc'),
          required: true
        },
      }
    };
  },
  mounted() {
    // 응답값 설정
    this.response = '----------------------------------------------------------------------------\n' +
        '[사이트 콜백 수신값]\n' +
        '----------------------------------------------------------------------------\n' +
        '{\n' +
        `  apiKey: "${this.$store.getters['auth/user'].apiKey}", // API으로부터 부여 받은 apiKey\n` +
        '  params: {\n' +
        '    transactionKey: \'6c6fa5112953ff1caad5a515c0e5dwc2\', // 거래코드(고유키)\n' +
        '    username: \'top1_user1\', // API에 등록된 회원 아이디(고유키)\n' +
        '    siteUsername: \'user1\', // 사이트에 등록된 회원 아이디\n' +
        '    vendorKey: \'pragmatic_slot\', // 게임사 코드\n' +
        '    vendorName: \'프라그마틱 슬롯\' // 게임사 명칭,\n' +
        '    gameCategoryKey: \'slot\', // 게임 카테고리\n' +
        '    gameKey: \'vs20doghouse\', // 게임 코드\n' +
        '    gameName: \'The Dog House\', // 게임명\n' +
        '    gameTypeKey: \'slot\', // 게임 형식\n' +
        '    gameImage: \'http://게임이미지 URL\', // 게임 형식\n' +
        '    type: \'cancel\', // 거래 형식(bet, win, draw, cancel, lose)\n' +
        '    key: \'631fd16a787fab3a031e1524c\', // 게임사로부터 전송 받은 게임 코드\n' +
        '    parentTransactionKey: \'6c6fa5112953ff1caad5a515c0e5cad9\', // 상위 거래\n' +
        '    isBonus: false, // 보너스 여부\n' +
        '    isJackpot: false, // 잭팟 여부\n' +
        '    isPromo: false, // 프로모션 여부\n' +
        '    isFreeGame: false, // 프리게임 여부\n' +
        '    groupKey: \'15\', // 사이트내 회원 구분 코드\n' +
        '    amount: 300, // 취소 금액\n' +
        '    createdAt: \'2022-09-13T00:40:10.921Z,\' // 처리 시간(UTC Time)\n' +
        '    requestedAt: \'2022-09-13T00:40:10.961Z\' // 요청 받은 시간(UTC Time)\n' +
        '  },\n' +
        '  requestAt: \'2022-09-13T00:40:10.923Z\' // API의 요청 시간\n' +
        '}\n\n' +
        '----------------------------------------------------------------------------\n' +
        '[성공 응답값]\n' +
        '----------------------------------------------------------------------------\n' +
        '  {\n' +
        '    code:0,\n' +
        '    data: {\n' +
        '      balance:9000, // 현재 잔액\n' +
        '      beforeBalance:10000, // 이전 잔액\n' +
        '      currency:"KRW"\n' +
        '    }\n' +
        '  }\n' +
        '----------------------------------------------------------------------------\n' +
        '[실패 응답값]\n' +
        '----------------------------------------------------------------------------\n' +
        '  {\n' +
        '    code: -1, // 실패코드로서 0이 아닌 아무 값이나 입력하시면 됩니다.\n' +
        '    msg: "NOT_VALID" // 디버깅을 위한 메세지\n' +
        '  }'
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title"><span class="badge bg-success">콜백 URL</span>
              {{ apiUrl }}
            </div>
            <div class="card-title-desc">취소 데이터를 사이트로 전송합니다.</div>
            <hr/>
            <Document :data="body" :apiUrl="apiUrl" :response="response" :require-vendors="false" :require-ip-address="false" :require-request-key="false" :requireSample="false"/>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
